import React from "react";
import SVG_LOGOS from "../../../assets/images/vector/pages/amzscout-vs-helium-10/HomeIntro/Intro_logos.svg";
import "./index.scss";

const Intro = () => {
  return (
    <section className="PgAVH-I">
      <div className="container">
        <div className="PgAVH-I-row">
          <h1 className="PgAVH-I-row__title">AMZScout vs Helium 10</h1>
          <p className="PgAVH-I-row__text PgAVH-I-row__text-oneParagraph">
            Third-party software can be an invaluable tool for Amazon sellers
            looking to plan, launch, and manage their business.
          </p>
          <p className="PgAVH-I-row__text">
            In this Helium 10 vs AMZScout review, we will compare the main
            features of two of the most popular Amazon selling tools. By the
            end, you’ll have a clear idea of which is best for you.
          </p>
          <img className="PgAVH-I-row__img" src={SVG_LOGOS} alt="img" />
        </div>
      </div>
    </section>
  );
};

export default Intro;
