import React from "react";
import SVG_HELIUM10 from "../../../../../assets/images/vector/pages/amzscout-vs-helium-10/Learning/Helium10_logo.svg";
import PRODUCT_RESEARCH_H10 from "./index.content";
import GetList from "../../../GetList";
import Screenshot from "../../../Screenshot";
import "./index.scss";

const H10 = () => {
  const { listsPictures, listsText } = PRODUCT_RESEARCH_H10;
  return (
    <div className="PgAVH-PR-H10">
      <div className="PgAVH-PR__imageWrap">
        <img src={SVG_HELIUM10} alt="x" className="PgAVH-PR-H10__logo" />
        <Screenshot
          listPictures={listsPictures}
          classes="PgAVH-PR-H10__firstScreenshot"
        />
      </div>
      <div className="PgAVH-PR-H10__textWrap">
        <p className="PgAVH-PR-H10__title">Helium 10 Product Research Tools</p>
        <GetList
          list={listsText}
          classes="PgAVH-PR-H10__listItem"
          classesImg="PgAVH-PR-H10__marker"
        />
      </div>
    </div>
  );
};

export default H10;
