import React from "react";
import PG_AVH_KEYWORDS_CONTENT from "./index.content";
import SVG_MARKER from "../../../assets/images/vector/components/common/tickInACircle.svg";
import SVG_AMZSCOUT from "../../../assets/images/vector/pages/amzscout-vs-helium-10/Keywords/AMZScout_logo.svg";
import SVG_HELIUM10 from "../../../assets/images/vector/pages/amzscout-vs-helium-10/Keywords/Helium10_logo.svg";
import Screenshot from "../Screenshot";
import GetList from "../GetList";
import "./index.scss";

const Keywords = () => {
  const { centralSection, bottomSection } = PG_AVH_KEYWORDS_CONTENT;

  return (
    <section className="PgAVH-K">
      <div className="PgAVH-K__wrapRowOne PgAVH-K-wrapRowOne">
        <div className="container">
          <div className="PgAVH-K__sectionTitle PgAVH-K-sectionTitle">
            <h2 className="PgAVH-K-sectionTitle__title">
              Keywords and Listing Optimization
            </h2>
            <p className="PgAVH-K-sectionTitle__text">
              Next in our AMZ Scout vs Helium 10 comparison, we will look at
              tools for keyword research and listing optimization
            </p>
          </div>
          <div className="PgAVH-K__centerSection">
            <div className="PgAVH-K__columOne PgAVH-K-columOne">
              <div className="PgAVH-K__imageWrap">
                <img
                  src={SVG_HELIUM10}
                  alt="x"
                  className="PgAVH-K-columOne__logo"
                />
                <Screenshot
                  listPictures={centralSection.listsPictures.Helium10}
                  classes="PgAVH-K-columOne__firstScreenshot"
                />
              </div>
              <div className="PgAVH-K-columOne__textWrap">
                <p className="PgAVH-K-columOne__title">Helium 10 Tools</p>
                <GetList
                  list={centralSection.Helium10}
                  classes="PgAVH-K-columOne__listItem"
                  classesImg="PgAVH-K-columOne__marker"
                />
              </div>
            </div>
            <div className="PgAVH-K__columTwo PgAVH-K-columTwo">
              <div className="PgAVH-K__imageWrap">
                <img
                  src={SVG_AMZSCOUT}
                  alt="x"
                  className="PgAVH-K-columTwo__logo"
                />
                <Screenshot
                  listPictures={centralSection.listsPictures.AMZScout}
                  classes="PgAVH-K-columTwo__firstScreenshot"
                />
              </div>
              <div className="PgAVH-K-columTwo__textWrap">
                <p className="PgAVH-K-columTwo__title">AMZScout Tools</p>
                <GetList
                  list={centralSection.AMZScout}
                  classes="PgAVH-K-columTwo__listItem"
                  classesImg="PgAVH-K-columTwo__marker"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="PgAVH-K-wrapRowOne__line  PgAVH-K-wrapRowOne__line-one" />
        <div className="PgAVH-K-wrapRowOne__line  PgAVH-K-wrapRowOne__line-two" />
        <div className="PgAVH-K-wrapRowOne__line  PgAVH-K-wrapRowOne__line-three" />
      </div>
      <div className="PgAVH-K__wrapRowTwo">
        <div className="container">
          <div className="PgAVH-K__bottomSection PgAVH-K-bottomSection">
            <p className="PgAVH-K-bottomSection__title">Key Differences</p>
            <p className="PgAVH-K-bottomSection__subTitle">
              The two platforms offer similar keyword research capabilities but
              there are a few differences
            </p>
            <div className="PgAVH-K-bottomSection__listWrap">
              <GetList
                list={bottomSection}
                classes="PgAVH-K-bottomSection__listItem"
                img={SVG_MARKER}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Keywords;
