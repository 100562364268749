import React from "react";
import SVG_NO_SUCH_TOOL from "../../../../assets/images/vector/pages/amzscout-vs-helium-10/ProductResearch/NoSuchTool.svg";
import H10 from "./H10";
import AMZS from "./AMZS";
import "./index.scss";

const CenterSection = () => {
  return (
    <div className="PgAVH-PR-CS">
      <H10 />
      <AMZS />
      <div className="PgAVH-PR-CS-noSuchTool">
        <img src={SVG_NO_SUCH_TOOL} alt="x" />
        <span>No such tool</span>
      </div>
    </div>
  );
};

export default CenterSection;
