import React from "react";
import SectionTitle from "./SectionTitle";
import BottomSection from "./BottomSection";
import CenterSection from "./CenterSection";
import "./index.scss";

const ProductResearch = () => {
  return (
    <section className="PgAVH-PR">
      <div className="PgAVH-PR-wrapRowOne">
        <div className="container">
          <SectionTitle />
          <CenterSection />
        </div>
        <div className="PgAVH-PR-wrapRowOne__line  PgAVH-PR-wrapRowOne__line-one" />
        <div className="PgAVH-PR-wrapRowOne__line  PgAVH-PR-wrapRowOne__line-two" />
        <div className="PgAVH-PR-wrapRowOne__line  PgAVH-PR-wrapRowOne__line-three" />
      </div>
      <div className="PgAVH-PR-wrapRowTwo">
        <div className="container">
          <BottomSection />
        </div>
      </div>
    </section>
  );
};

export default ProductResearch;
