import React from "react";
import SVG_LOGOS from "../../../assets/images/vector/pages/amzscout-vs-helium-10/Conclusion/Conclusion_logos.svg";
import "./index.scss";

const Conclusion = () => {
  return (
    <section className="PgAVH-C">
      <div className="container">
        <div className="PgAVH-C__wrap">
          <img className="PgAVH-C__img" src={SVG_LOGOS} alt="img" />
          <div className="PgAVH-C__sectionTitle PgAVH-C-sectionTitle">
            <h2 className="PgAVH-C-sectionTitle__title">
              Which Tool is Better?
            </h2>
            <p className="PgAVH-C-sectionTitle__text">
              In summary, AMZScout is the preferred tool for many Amazon
              sellers. It gives you all the tools you need to be successful on
              Amazon. More importantly, it is affordable and does not include
              features you don’t need. Whether you are a new or experienced
              seller, the platform will give you the most value.
            </p>
            <p className="PgAVH-C-sectionTitle__text">
              <b>
                Get started with AMZScout today to see what it can do for you!
              </b>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Conclusion;
