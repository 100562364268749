import React from "react";
import PropTypes from "prop-types";
import About from "./About";
import ReviewsCount from "./ReviewsCount";
import Reviews from "./Reviews";
import "./index.scss";

const Column = ({ data }) => {
  const { about, reviewsCount, reviews } = data;
  return (
    <div className="PgAVH-TS-C-C">
      <About data={about} />
      <ReviewsCount data={reviewsCount} />
      <Reviews data={reviews} />
    </div>
  );
};
Column.propTypes = {
  data: PropTypes.object,
};

export default Column;
