import React from "react";
import Title from "./Title";
import Comparison from "./Comparison";
import Board from "./Board";
import "./index.scss";

const UsersSaying = ({ usersSayingRef }) => (
  <section className="PgAVH-US">
    <div className="PgAVH-US__ref" ref={usersSayingRef} />
    <Title />
    <Comparison />
    <Board />
  </section>
);

export default UsersSaying;
