import React from "react";
import { PAGES } from "../../../helpers/constants";
import { PG_AVJS_FORM_THREE_CONTENT } from "../../common/Forms/SectionForm.content";
import StandardEmailCollectContainer from "../../../containers/common/forms/StandardEmailCollectContainer";
import GetScreen from "../GetScreen";
import "./index.scss";

const FormThree = () => {
  return (
    <section className="PgAVH-FormThree">
      <div className="container">
        <div className="PgAVH-FormThree__wrap">
          <div className="PgAVH-FormThree__wrapForm">
            <StandardEmailCollectContainer
              page={PAGES.AMZSCOUT_VS_HELIUM_10}
              buttonTextFirstState="Try AMZScout for free!"
              buttonTextSecondState="Try AMZScout for free!"
              title="Try AMZScout Product & Keyword Research Tools for FREE"
              placeholderText="example@gmail.com"
              isNewForm
              staticTopTitle="Try AMZScout Product & Keyword Research Tools for FREE"
              customClass="PgAVH-FormThree__emailCollect"
            />
          </div>
          <GetScreen
            classes="PgAVH-FormThree__img"
            listPictures={PG_AVJS_FORM_THREE_CONTENT}
            isSectionForm
          />
        </div>
      </div>
    </section>
  );
};

export default FormThree;
