import deskImgHelium10Png from "../../../assets/images/raster/pages/amzscout-vs-helium-10/Keywords/Keywords_Helium10@desk.png"
import deskImgHelium10Png2x from "../../../assets/images/raster/pages/amzscout-vs-helium-10/Keywords/Keywords_Helium10@desk2x.png"
import deskImgHelium10Webp from "../../../assets/images/raster/pages/amzscout-vs-helium-10/Keywords/Keywords_Helium10@desk.webp"
import deskImgHelium10Webp2x from "../../../assets/images/raster/pages/amzscout-vs-helium-10/Keywords/Keywords_Helium10@desk2x.webp"

import mobImgHelium10Png from "../../../assets/images/raster/pages/amzscout-vs-helium-10/Keywords/Keywords_Helium10@mob.png"
import mobImgHelium10Png2x from "../../../assets/images/raster/pages/amzscout-vs-helium-10/Keywords/Keywords_Helium10@mob2x.png"
import mobImgHelium10Webp from "../../../assets/images/raster/pages/amzscout-vs-helium-10/Keywords/Keywords_Helium10@mob.webp"
import mobImgHelium10Webp2x from "../../../assets/images/raster/pages/amzscout-vs-helium-10/Keywords/Keywords_Helium10@mob2x.webp"

import deskImgAMZScoutPng from "../../../assets/images/raster/pages/amzscout-vs-helium-10/Keywords/Keywords_AMZScout@desk.png"
import deskImgAMZScoutPng2x from "../../../assets/images/raster/pages/amzscout-vs-helium-10/Keywords/Keywords_AMZScout@desk2x.png"
import deskImgAMZScoutWebp from "../../../assets/images/raster/pages/amzscout-vs-helium-10/Keywords/Keywords_AMZScout@desk.webp"
import deskImgAMZScoutWebp2x from "../../../assets/images/raster/pages/amzscout-vs-helium-10/Keywords/Keywords_AMZScout@desk2x.webp"

import mobImgAMZScoutPng from "../../../assets/images/raster/pages/amzscout-vs-helium-10/Keywords/Keywords_AMZScout@mob.png"
import mobImgAMZScoutPng2x from "../../../assets/images/raster/pages/amzscout-vs-helium-10/Keywords/Keywords_AMZScout@mob2x.png"
import mobImgAMZScoutWebp from "../../../assets/images/raster/pages/amzscout-vs-helium-10/Keywords/Keywords_AMZScout@mob.webp"
import mobImgAMZScoutWebp2x from "../../../assets/images/raster/pages/amzscout-vs-helium-10/Keywords/Keywords_AMZScout@mob2x.webp"

const PG_AVH_KEYWORDS_CONTENT = {
  centralSection: {
    listsPictures: {
      Helium10: {
        deskImgPng: deskImgHelium10Png,
        deskImgPng2x: deskImgHelium10Png2x,
        deskImgWebp: deskImgHelium10Webp,
        deskImgWebp2x: deskImgHelium10Webp2x,

        mobImgPng: mobImgHelium10Png,
        mobImgPng2x: mobImgHelium10Png2x,
        mobImgWebp: mobImgHelium10Webp,
        mobImgWebp2x: mobImgHelium10Webp2x,
      },
      AMZScout: {
        deskImgPng: deskImgAMZScoutPng,
        deskImgPng2x: deskImgAMZScoutPng2x,
        deskImgWebp: deskImgAMZScoutWebp,
        deskImgWebp2x: deskImgAMZScoutWebp2x,

        mobImgPng: mobImgAMZScoutPng,
        mobImgPng2x: mobImgAMZScoutPng2x,
        mobImgWebp: mobImgAMZScoutWebp,
        mobImgWebp2x: mobImgAMZScoutWebp2x,
      },
    },
    Helium10: [
      [
        { textParagraph: "Cerebro Reverse Product Lookup" },
        {
          textParagraph:
            "Cerebro lets you enter an Amazon ASIN to see all the keywords that the product ranks for. The tool comes with an option to exclude variations so that your results aren’t too redundant.",
        },
      ],
      [
        { textParagraph: "Magnet Keyword Research" },
        {
          textParagraph:
            "Magnet is a traditional keyword research tool that allows you to enter a search term to see its monthly search volume and how it’s trending over time",
        },
      ],
      [
        { textParagraph: "Keyword Index Checker" },
        {
          textParagraph:
            "This tool allows sellers to check if their products are appearing in the search results for their target keyword. You can use the tool to check multiple keywords at once.",
        },
      ],
      [
        { textParagraph: "Keyword Tracker" },
        {
          textParagraph:
            "Helium 10’s Keyword Tracker lets you monitor important keywords. You can use this to see what works well and how you stack up to competitors.",
        },
      ],
      [
        { textParagraph: "Listing Optimization Tools" },
        {
          textParagraph:
            "Helium 10 offers the following listing optimization tools:",
        },
        {
          list: [
            {
              boldText: "Frankenstein Keyword Processor: ",
              text: "Takes a list of keywords and filters out redundancies",
            },
            {
              boldText: "Scribbles: ",
              text: "Listing creation interface that lets you add your target keywords",
            },
            {
              boldText: "Listing Optimizer: ",
              text: "A Beta tool that lets you create an optimized listing. It is similar to Scribbles but also includes a listing analysis.",
            },
            {
              boldText: "Listing Analyzer: ",
              text: "Lets you review the quality of any Amazon listing. Pages are scored from 1 - 10.",
            },
          ],
        },
      ],
    ],
    AMZScout: [
      [
        { textParagraph: "Reverse ASIN Lookup" },
        {
          textParagraph:
            "The Reverse ASIN Lookup tool lets you analyze any Amazon listing to find its ranking keywords. You can use this to quickly determine the best terms for your business by reviewing your competitors’ products. It includes the same filters as the search tool.",
        },
      ],
      [
        { textParagraph: "Amazon Keyword Search" },
        {
          textParagraph:
            "With this keyword research tool, you can enter any search phrase to find matching relevant keywords on Amazon. The results will show the monthly search volume as well as the search volume history. There are filters that let you refine your results by search volume or the number of words in the term.",
        },
      ],
      [
        { textParagraph: "Keyword Tracker & Index Checker Extension" },
        {
          textParagraph:
            "This tool lets you add your Amazon products to track where they are ranking for important keywords. You can use this to improve the efficiency of your ad campaigns by not bidding on keywords that you already rank for. The tool also shows how your rankings are changing over time.",
        },
        {
          textParagraph:
            "In addition to these tools, there is another great feature in the AMZScout PRO Extension. With it, you can write one search term and quickly bring up new keyword ideas by clicking “Niche Keywords” as seen in the image below.",
        },
      ],
      [
        { textParagraph: "Listing Quality Score" },
        {
          textParagraph:
            "AMZScout also has its Listing Quality Score. Search volume stats are good for assessing demand but do not tell you how difficult it will be to rank for the term. You can use this to see if your potential rivals have listings that are difficult to compete with.",
        },
      ],
    ],
  },
  bottomSection: [
    [
      { textParagraph: "Built-in Google Trends" },
      {
        textParagraph:
          "The AMZScout PRO Extension shows how your chosen search term is doing on Google Trends",
      },
    ],
    [
      { textParagraph: "Extended Features" },
      {
        textParagraph:
          "With its expensive pricing plans, Helium 10 gives sellers extra tools for keyword research and listing optimization. If you are a new seller or still only receive a limited amount of orders you can save your budget by using AMZScout. The platform has all the tools you need to conduct efficient keyword research and that extra money can go to more products or ads.",
      },
    ],
  ],
}
export default PG_AVH_KEYWORDS_CONTENT
