import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

const About = ({ data }) => {
  const { logo, title } = data;
  return (
    <div className="PgAVH-US-Comparison-Column-About">
      <img
        src={logo}
        alt="logo"
        className="PgAVH-US-Comparison-Column-About__logo"
      />
      <h3 className="PgAVH-US-Comparison-Column-About__title">{title}</h3>
    </div>
  );
};
About.propTypes = {
  data: PropTypes.object,
};

export default About;
