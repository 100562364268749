import PNG_ONE from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/ProductResearch/ProductResearch_AMZScout_01@desk.png";
import PNG_2x_ONE from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/ProductResearch/ProductResearch_AMZScout_01@desk2x.png";
import WEBP_ONE from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/ProductResearch/ProductResearch_AMZScout_01@desk.webp";
import WEBP_2x_ONE from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/ProductResearch/ProductResearch_AMZScout_01@desk2x.webp";

import PNG_Mob_ONE from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/ProductResearch/ProductResearch_AMZScout_01@mob.png";
import PNG_Mob2x_ONE from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/ProductResearch/ProductResearch_AMZScout_01@mob2x.png";
import WEBP_Mob_ONE from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/ProductResearch/ProductResearch_AMZScout_01@mob.webp";
import WEBP_Mob2x_ONE from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/ProductResearch/ProductResearch_AMZScout_01@mob2x.webp";

import PNG_TWO from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/ProductResearch/ProductResearch_AMZScout_02@desk.png";
import PNG_2x_TWO from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/ProductResearch/ProductResearch_AMZScout_02@desk2x.png";
import WEBP_TWO from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/ProductResearch/ProductResearch_AMZScout_02@desk.webp";
import WEBP_2x_TWO from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/ProductResearch/ProductResearch_AMZScout_02@desk2x.webp";

import PNG_Mob_TWO from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/ProductResearch/ProductResearch_AMZScout_02@mob.png";
import PNG_Mob2x_TWO from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/ProductResearch/ProductResearch_AMZScout_02@mob2x.png";
import WEBP_Mob_TWO from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/ProductResearch/ProductResearch_AMZScout_02@mob.webp";
import WEBP_Mob2x_TWO from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/ProductResearch/ProductResearch_AMZScout_02@mob2x.webp";

const PRODUCT_RESEARCH_AMZS = {
  listsPictures: {
    firstScreenshot: {
      deskImgPng: PNG_ONE,
      deskImgPng2x: PNG_2x_ONE,
      deskImgWebp: WEBP_ONE,
      deskImgWebp2x: WEBP_2x_ONE,

      mobImgPng: PNG_Mob_ONE,
      mobImgPng2x: PNG_Mob2x_ONE,
      mobImgWebp: WEBP_Mob_ONE,
      mobImgWebp2x: WEBP_Mob2x_ONE
    },
    secondScreenshot: {
      deskImgPng: PNG_TWO,
      deskImgPng2x: PNG_2x_TWO,
      deskImgWebp: WEBP_TWO,
      deskImgWebp2x: WEBP_2x_TWO,

      mobImgPng: PNG_Mob_TWO,
      mobImgPng2x: PNG_Mob2x_TWO,
      mobImgWebp: WEBP_Mob_TWO,
      mobImgWebp2x: WEBP_Mob2x_TWO
    }
  },
  listsText: [
    [
      { textParagraph: "Product Database" },
      {
        textParagraph:
          "The AMZScout Product Database gives users a comprehensive database of hundreds of millions of Amazon products. Like Helium 10, you can use the tool to search for a particular type of item to see its performance metrics. This includes estimated revenue and sales, fees, weight, product history, ratings, and reviews."
      },
      {
        textParagraph:
          "There are over a dozen filters that you can use to curate the results according to your needs. There are also two macro-level filters: New Products and Trending Products. You can use these to further refine your results to show fresh and in-demand items."
      }
    ],
    [
      { textParagraph: "PRO Extension" },
      {
        textParagraph:
          "The AMZScout PRO Extension is a beginner-friendly and easy-to-use Google Chrome and Edge add-on that lets you perform product research while on Amazon’s website. When you open the app for a product or niche, you can see all the data available in the Product Database. There are also additional metrics."
      },
      {
        textParagraph:
          "Among these are Product Score for private label and reselling, net margin, and revenue per review. At the top of the app, you can see average stats for the niche. There is also a niche score (measured on a scale of 1 - 10) that assesses the overall quality of the market."
      }
    ],
    [
      { textParagraph: "Product Tracker" },
      {
        textParagraph:
          "Additionally, the web app includes a Product Tracker that lets you keep track of the products you select. You can use this to monitor the average price, daily sales, and other key metrics for your important products."
      }
    ],
    [
      {
        textParagraph: "Amazon Dropshipping, Arbitrage, and Wholesale Extension",
        icon: "exclusive"
      },
      {
        textParagraph:
          "This tool was designed to help sellers that get their items through arbitrage, wholesale or dropshipping. With it, you can quickly see the buy box price history, the number of sellers, and other metrics that will let you know if an item is viable with one of these sourcing methods. There is also a calculator you can use to find your profit. Helium 10 has no such tool."
      }
    ]
  ]
};
export default PRODUCT_RESEARCH_AMZS;
