import React from "react";
import "./index.scss";

const Board = () => (
  <div className="PgAVH-US-Board">
    <div className="container">
      <div className="PgAVH-US-Board__wrap">
        <p className="PgAVH-US-Board__text">
          Conversely, AMZScout offers transparent and intuitive plans, fast and
          easy refunds, and a loyal approach when it comes to all payments.
        </p>
      </div>
    </div>
  </div>
);

export default Board;
