import React from "react";
import PropTypes from "prop-types";

const ParagraphWithList = ({ list, classes }) => {
  const className = `${classes}-list`;
  return (
    <div className={className}>
      {list.map(({ boldText, text }, i) => (
        <p key={`${i}${className}`}>
          <b>{boldText}</b> {text}
        </p>
      ))}
    </div>
  );
};
ParagraphWithList.propTypes = {
  list: PropTypes.array,
  classes: PropTypes.string,
};
export default ParagraphWithList;
