import React from "react";
import SVG_AMZSCOUT from "../../../../../assets/images/vector/pages/amzscout-vs-helium-10/Learning/AMZScout_logo.svg";
import PRODUCT_RESEARCH_AMZS from "./index.content";
import GetList from "../../../GetList";
import Screenshot from "../../../Screenshot";
import "./index.scss";

const AMZS = () => {
  const {
    listsPictures: { firstScreenshot, secondScreenshot },
    listsText,
  } = PRODUCT_RESEARCH_AMZS;
  return (
    <div className="PgAVH-PR-AMZS">
      <div className="PgAVH-PR__imageWrap">
        <img src={SVG_AMZSCOUT} alt="x" className="PgAVH-PR-AMZS__logo" />
        <Screenshot
          listPictures={firstScreenshot}
          classes="PgAVH-PR-AMZS__firstScreenshot"
        />
        <Screenshot
          listPictures={secondScreenshot}
          classes="PgAVH-PR-AMZS__secondScreenshot"
        />
      </div>
      <div className="PgAVH-PR-AMZS__textWrap">
        <p className="PgAVH-PR-AMZS__title">AMZScout Product Research Tools</p>
        <GetList
          list={listsText}
          classes="PgAVH-PR-AMZS__listItem"
          classesImg="PgAVH-PR-AMZS__marker"
        />
      </div>
    </div>
  );
};

export default AMZS;
