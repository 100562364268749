import React from "react";
import PropTypes from "prop-types";
import SVG_ONE_STAR from "../../../../../../assets/images/vector/pages/amzscout-vs-helium-10/TechnicalSupport/Trustpilot_1_stars.svg";
import SVG_FIVE_STARS from "../../../../../../assets/images/vector/pages/amzscout-vs-helium-10/TechnicalSupport/Trustpilot_5_stars.svg";
import SimpleTitle from "../../../../SimpleTitle";
import Count from "./Count";
import "./index.scss";

const ReviewsCount = ({ data }) => {
  const { oneStarSum, fiveStarsSum } = data;
  return (
    <div className="PgAVH-TS-C-C-RC">
      <SimpleTitle title="In 2022" classes="PgAVH-TS-C-C-RC-title" />
      <Count text="1-star reviews" img={SVG_ONE_STAR} sum={oneStarSum} />
      <Count text="5-star reviews" img={SVG_FIVE_STARS} sum={fiveStarsSum} />
    </div>
  );
};
ReviewsCount.propTypes = {
  data: PropTypes.object,
};

export default ReviewsCount;
