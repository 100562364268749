import React, { useRef } from "react";
import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import OgImg from "../assets/images/raster/components/common/mainscreen-kwTracker-intro@xxl.png";
import Intro from "../components/amzscout-vs-helium-10/Intro";
import LearnMore from "../components/amzscout-vs-helium-10/LearnMore";
import ProductResearch from "../components/amzscout-vs-helium-10/ProductResearch";
import FreeTools from "../components/amzscout-vs-helium-10/FreeTools";
import Keywords from "../components/amzscout-vs-helium-10/Keywords";
import Learning from "../components/amzscout-vs-helium-10/Learning";
import TechnicalSupport from "../components/amzscout-vs-helium-10/TechnicalSupport";
import BestPricing from "../components/amzscout-vs-helium-10/BestPricing";
import UsersSaying from "../components/amzscout-vs-helium-10/UsersSaying";
import Conclusion from "../components/amzscout-vs-helium-10/Conclusion";
import FormTwo from "../components/amzscout-vs-helium-10/FormTwo";
import FormThree from "../components/amzscout-vs-helium-10/FormThree";
import FormFour from "../components/amzscout-vs-helium-10/FormFour";
import CustomizableContainer from "../containers/Paywalls/Customizable/CustomizableContainer";
import "../components/amzscout-vs-helium-10/index.scss";

const PgAVH = () => {
  const pricingRef = useRef(null);
  const usersSayingRef = useRef(null);
  return (
    <Layout customClass="PgAVH-page" tariffsElement={pricingRef}>
      <Seo
        title="AMZScout vs Helium 10: Comparison of Tools, Extensions and Pricing"
        description="Are you deciding between AMZScout and Helium 10? What is the best product and keyword research software to start an Amazon business? Chrome extensions and other tools review and comparison."
        page="amzscout-vs-helium-10"
        manifest="browserconfig.xml"
        ogImg={OgImg}
        keywords={["amzscout vs helium 10, helium 10 vs amzscout"]}
      />
      <main className="PgAVH">
        <Intro />
        <LearnMore usersSayingRef={usersSayingRef} />
        <ProductResearch />
        <FormTwo />
        <FreeTools />
        <Keywords />
        <FormThree />
        <Learning />
        <TechnicalSupport />
        <BestPricing pricingRef={pricingRef} />
        <UsersSaying usersSayingRef={usersSayingRef} />
        <Conclusion />
        <FormFour />
        <CustomizableContainer
          pricingRef={pricingRef}
          customClass="PgAVH-Pricing"
          subTitlePro
        />
      </main>
    </Layout>
  );
};

export default PgAVH;
