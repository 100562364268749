import deskHelium10Png from "../../../assets/images/raster/pages/amzscout-vs-helium-10/Learning/Learning_Helium10@desk.png"
import deskHelium10Png2x from "../../../assets/images/raster/pages/amzscout-vs-helium-10/Learning/Learning_Helium10@desk2x.png"
import deskHelium10Webp from "../../../assets/images/raster/pages/amzscout-vs-helium-10/Learning/Learning_Helium10@desk.webp"
import deskHelium10Webp2x from "../../../assets/images/raster/pages/amzscout-vs-helium-10/Learning/Learning_Helium10@desk2x.webp"
import mobHelium10Png from "../../../assets/images/raster/pages/amzscout-vs-helium-10/Learning/Learning_Helium10@mob.png"
import mobHelium10Png2x from "../../../assets/images/raster/pages/amzscout-vs-helium-10/Learning/Learning_Helium10@mob2x.png"
import mobHelium10Webp from "../../../assets/images/raster/pages/amzscout-vs-helium-10/Learning/Learning_Helium10@mob.webp"
import mobHelium10Webp2x from "../../../assets/images/raster/pages/amzscout-vs-helium-10/Learning/Learning_Helium10@mob2x.webp"

import deskAMZScoutPng from "../../../assets/images/raster/pages/amzscout-vs-helium-10/Learning/Learning_AMZScout@desk.png"
import deskAMZScoutPng2x from "../../../assets/images/raster/pages/amzscout-vs-helium-10/Learning/Learning_AMZScout@desk2x.png"
import deskAMZScoutWebp from "../../../assets/images/raster/pages/amzscout-vs-helium-10/Learning/Learning_AMZScout@desk.webp"
import deskAMZScoutWebp2x from "../../../assets/images/raster/pages/amzscout-vs-helium-10/Learning/Learning_AMZScout@desk2x.webp"
import mobAMZScoutPng from "../../../assets/images/raster/pages/amzscout-vs-helium-10/Learning/Learning_AMZScout@mob.png"
import mobAMZScoutPng2x from "../../../assets/images/raster/pages/amzscout-vs-helium-10/Learning/Learning_AMZScout@mob2x.png"
import mobAMZScoutWebp from "../../../assets/images/raster/pages/amzscout-vs-helium-10/Learning/Learning_AMZScout@mob.webp"
import mobAMZScoutWebp2x from "../../../assets/images/raster/pages/amzscout-vs-helium-10/Learning/Learning_AMZScout@mob2x.webp"

const PG_AVH_LEARNING_CONTENT = {
  listsPictures: {
    Helium10: {
      deskImgPng: deskHelium10Png,
      deskImgPng2x: deskHelium10Png2x,
      deskImgWebp: deskHelium10Webp,
      deskImgWebp2x: deskHelium10Webp2x,
      mobImgPng: mobHelium10Png,
      mobImgPng2x: mobHelium10Png2x,
      mobImgWebp: mobHelium10Webp,
      mobImgWebp2x: mobHelium10Webp2x,
    },
    AMZScout: {
      deskImgPng: deskAMZScoutPng,
      deskImgPng2x: deskAMZScoutPng2x,
      deskImgWebp: deskAMZScoutWebp,
      deskImgWebp2x: deskAMZScoutWebp2x,
      mobImgPng: mobAMZScoutPng,
      mobImgPng2x: mobAMZScoutPng2x,
      mobImgWebp: mobAMZScoutWebp,
      mobImgWebp2x: mobAMZScoutWebp2x,
    },
  },
  listParagraphs: {
    Helium10: [
      [
        { textParagraph: "Freedom Ticket Amazon FBA Course" },
        {
          textParagraph:
            "This comprehensive course goes through all the steps needed to run an FBA business. It is presented via video and there are downloadable resources for each lesson. The course is available with the entry-level plan.",
        },
      ],
      [
        { textParagraph: "Webinars" },
        {
          textParagraph:
            "Helium 10 has an archive of educational webinars. These feature walkthroughs on various topics, hosted by knowledgable Amazon sellers.",
        },
      ],
      [
        { textParagraph: "Helium 10 Elite" },
        {
          textParagraph:
            "Helium 10 Elite is a separate online community for high-volume sellers. To access this feature you need to pay an additional fee.",
        },
      ],
    ],
    AMZScout: [
      [
        { textParagraph: "Amazon Seller Course for Private Label Sellers" },
        {
          textParagraph:
            "The course for private label includes video lessons detailing how to find winning private label products for your Amazon business",
        },
      ],
      [
        { textParagraph: "Free and Premium Masterclasses" },
        {
          textParagraph:
            "AMZScout offers a mix of free and paid masterclasses. These comprehensive learning resources are taught by experienced Amazon sellers. They provide unique insights into running an Amazon business that most new sellers neglect.",
        },
      ],
      [
        {
          textParagraph:
            "Seller Training for Dropshippers, Arbitrage Sellers, and Wholesalers",
          icon: "exclusive",
        },
        {
          textParagraph:
            "This course walks through all the steps needed to run an Amazon business using resell methods such as dropshipping, arbitrage, and wholesale",
        },
      ],
    ],
  },
}

export default PG_AVH_LEARNING_CONTENT
