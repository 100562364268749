import JPEG_AMZS from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/TechnicalSupport/AMZS_recall.jpg"
import JPEG2x_AMZS from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/TechnicalSupport/AMZS_recall@2x.jpg"
import JPEGMob_AMZS from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/TechnicalSupport/AMZS_recall@mob.jpg"
import JPEGMob2x_AMZS from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/TechnicalSupport/AMZS_recall@mob_2x.jpg"
import WEBP_AMZS from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/TechnicalSupport/AMZS_recall.webp"
import WEBP2x_AMZS from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/TechnicalSupport/AMZS_recall@2x.webp"
import WEBPMob_AMZS from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/TechnicalSupport/AMZS_recall@mob.webp"
import WEBPMob2x_AMZS from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/TechnicalSupport/AMZS_recall@mob_2x.webp"

import JPEG_H10 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/TechnicalSupport/H10_recall.jpg"
import JPEG2x_H10 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/TechnicalSupport/H10_recall@2x.jpg"
import JPEGMob_H10 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/TechnicalSupport/H10_recall@mob.jpg"
import JPEGMob2x_H10 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/TechnicalSupport/H10_recall@mob_2x.jpg"
import WEBP_H10 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/TechnicalSupport/H10_recall.webp"
import WEBP2x_H10 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/TechnicalSupport/H10_recall@2x.webp"
import WEBPMob_H10 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/TechnicalSupport/H10_recall@mob.webp"
import WEBPMob2x_H10 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/TechnicalSupport/H10_recall@mob_2x.webp"

import SVG_LOGO_H10 from "../../../../../assets/images/vector/pages/amzscout-vs-helium-10/TechnicalSupport/TS_Helium_10_logo.svg"
import SVG_LOGO_AMZS from "../../../../../assets/images/vector/pages/amzscout-vs-helium-10/TechnicalSupport/TS_AMZScout_logo.svg"
import SVG_STARS_2 from "../../../../../assets/images/vector/pages/amzscout-vs-helium-10/TechnicalSupport/Trustpilot_2_stars.svg";
import SVG_STARS_4_5 from "../../../../../assets/images/vector/pages/amzscout-vs-helium-10/TechnicalSupport/Trustpilot_4.5_stars.svg"

export  const AMZS_CONTENT = {
  about: {
    logo: SVG_LOGO_AMZS,
    stars: {
      sum: "4.6",
      img: SVG_STARS_4_5,
    },
    ratio: {
      excellent: "80",
      bad: "9"
    },
  },
  reviewsCount: {
    oneStarSum: "8",
    fiveStarsSum: "70+",
  },
  reviews: {
    title: "At the same time, every fifth user who leaves a positive review on AMZScout has mentioned their great support team.",
    screenshot: {
      JPEG: JPEG_AMZS,
      JPEG2x: JPEG2x_AMZS,
      JPEGMob: JPEGMob_AMZS,
      JPEGMob2x: JPEGMob2x_AMZS,
      WEBP: WEBP_AMZS,
      WEBP2x: WEBP2x_AMZS,
      WEBPMob: WEBPMob_AMZS,
      WEBPMob2x: WEBPMob2x_AMZS,
    },
  }
}

export const H10_CONTENT = {
  about: {
    logo: SVG_LOGO_H10,
    stars: {
      sum: "1.9",
      img: SVG_STARS_2,
    },
    ratio: {
      excellent: "51",
      bad: "33"
    },
  },
  reviewsCount: {
    oneStarSum: "30+",
    fiveStarsSum: "1",
  },
  reviews: {
    title: "At least 10 out of 30+ one-star reviews on Helium 10 mention poor support. Here are some of them:",
    screenshot: {
      JPEG: JPEG_H10,
      JPEG2x: JPEG2x_H10,
      JPEGMob: JPEGMob_H10,
      JPEGMob2x: JPEGMob2x_H10,
      WEBP: WEBP_H10,
      WEBP2x: WEBP2x_H10,
      WEBPMob: WEBPMob_H10,
      WEBPMob2x: WEBPMob2x_H10,
    },
  }
}
