import React from "react";
import PropTypes from "prop-types";
import SimpleTitle from "../../../../SimpleTitle";
import SimpleScreenshot from "../../../../../common/UI/SimpleScreenshot";
import "./index.scss";

const Reviews = ({ data }) => {
  const { title, screenshot } = data;
  return (
    <div className="PgAVH-TS-C-C-R">
      <SimpleTitle title={title} classes="PgAVH-TS-C-C-R-title" />
      <SimpleScreenshot
        from={768}
        to={767}
        classes="PgAVH-TS-C-C-R__Screenshot"
        screenshot={screenshot}
      />
    </div>
  );
};
Reviews.propTypes = {
  data: PropTypes.object,
};

export default Reviews;
