import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

const Count = ({ text, img, sum }) => {
  return (
    <div className="PgAVH-TS-C-C-RC-C">
      <p className="PgAVH-TS-C-C-RC-C__text">{text}</p>
      <img src={img} alt="-" />
      <p className="PgAVH-TS-C-C-RC-C__sum">{sum}</p>
    </div>
  );
};
Count.propTypes = {
  text: PropTypes.string,
  img: PropTypes.string,
  sum: PropTypes.string,
};
export default Count;
