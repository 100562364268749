import React from "react";
import PropTypes from "prop-types";
import Marker from "./Marker";
import Item from "./Item";

const GetList = ({ list, classes, img, classesImg }) => {
  return (
    <ul>
      {list.map((t, i) => (
        <li key={i}>
          <Marker img={img} classesImg={classesImg} />
          <Item list={t} classes={classes} />
        </li>
      ))}
    </ul>
  );
};
GetList.propTypes = {
  list: PropTypes.array,
  classes: PropTypes.string,
  classesImg: PropTypes.string,
  img: PropTypes.string,
};
export default GetList;
