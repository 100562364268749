import React from "react";
import BEST_PRICING_CENTER_SECTION from "./index.content";
import GetList from "../../GetList";
import ScrollLink from "../../../common/ScrollLink";
import "./index.scss";

const CenterSection = ({ pricingRef }) => {
  const { Helium_10, AMZScout } = BEST_PRICING_CENTER_SECTION;

  return (
    <div className="PgAVH-BP__CenterSection">
      <div className="PgAVH-BP__columOne PgAVH-BP-columOne">
        <div className="PgAVH-BP-columOne__textWrap">
          <p className="PgAVH-BP-columOne__title">Helium 10 Pricing</p>
          <GetList
            list={Helium_10}
            classes="PgAVH-BP-columOne__listItem"
            classesImg="PgAVH-BP-columOne__marker"
          />
        </div>
      </div>
      <div className="PgAVH-BP__columTwo PgAVH-BP-columTwo">
        <div className="PgAVH-BP-columTwo__textWrap">
          <p className="PgAVH-BP-columTwo__title">AMZScout Pricing</p>
          <GetList
            list={AMZScout}
            classes="PgAVH-BP-columTwo__listItem"
            classesImg="PgAVH-BP-columTwo__marker"
          />
          <p className="PgAVH-BP-columTwo__footnote">
            * Amazon Dropshipping, Arbitrage, and Wholesale Extension
          </p>
          <ScrollLink
            refParameter={pricingRef}
            classes="PgAVH-BP-columTwo__link"
          >
            Сlick here to see the actual pricing table
          </ScrollLink>
        </div>
      </div>
    </div>
  );
};

export default CenterSection;
