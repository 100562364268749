import React from "react";
import Title from "./Title";
import Comparison from "./Comparison";
import Info from "./Info";
import "./index.scss";

const TechnicalSupport = () => {
  return (
    <section className="PgAVH-TS">
      <Title />
      <Comparison />
      <Info />
    </section>
  );
};

export default TechnicalSupport;
