import React from "react";
import PropTypes from "prop-types";
import Stars from "./Stars";
import Ratio from "./Ratio";
import "./index.scss";

const About = ({ data }) => {
  const { logo, stars, ratio } = data;
  return (
    <div className="PgAVH-TS-C-C-A">
      <div className="PgAVH-TS-C-C-A__wrapLogo">
        <img src={logo} alt="logo" className="PgAVH-TS-C-C-A__logo" />
      </div>
      <Stars data={stars} />
      <Ratio data={ratio} />
    </div>
  );
};
About.propTypes = {
  data: PropTypes.object,
};

export default About;
