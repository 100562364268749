import React from "react";
import Board from "./Board";
import TrackedLink from "../../../Analytics/TrackedLink";
import "./index.scss";

const Info = () => {
  return (
    <div className="PgAVH-TS__I PgAVH-TS-I">
      <div className="container">
        <Board />
        <p className="PgAVH-TS-I__ps">
          You can read all of the customer reviews about&nbsp;
          <TrackedLink
            key="InfoH10"
            class="PgAVH-TS-I__link"
            category="amzscoutVsHelium10"
            action="Helium10"
            target
            path="https://www.trustpilot.com/review/www.helium10.com?sort=recency"
          >
            Helium 10
          </TrackedLink>
          &nbsp;and&nbsp;
          <TrackedLink
            key="InfoAMZScout"
            class="PgAVH-TS-I__link"
            category="AMZScoutVsHelium10LP"
            action="AMZScout"
            target
            path="https://www.trustpilot.com/review/amzscout.net?sort=recency"
          >
            AMZScout
          </TrackedLink>
          &nbsp;on Trustpilot to help you make an informed decision.
        </p>
      </div>
    </div>
  );
};

export default Info;
