import React from "react";
import "./index.scss";

const TitleSection = () => (
  <div className="PgAVH-BP__TitleSection PgAVH-BP-TitleSection">
    <h2 className="PgAVH-BP-TitleSection__title">Pricing</h2>
    <p className="PgAVH-BP-TitleSection__text">
      Finally, we will look at pricing. Both platforms offer new users a free
      trial. Below you can see the payment plans for each software:
    </p>
  </div>
);

export default TitleSection;
