import React from "react";
import PropTypes from "prop-types";

const ParagraphWithIcon = ({ obj, classes }) => {
  const { textParagraph, icon } = obj;
  const spanClassName = `${classes}__icon ${classes}__icon-${icon}`;
  return (
    <p>
      {textParagraph} <span className={spanClassName}>{icon}</span>
    </p>
  );
};
ParagraphWithIcon.propTypes = {
  obj: PropTypes.object,
  classes: PropTypes.string,
};
export default ParagraphWithIcon;
