import React from "react";
import PropTypes from "prop-types";

const SimpleTitle = ({ title, classes }) => (
  <div className={classes}>
    <div className={`${classes}__marker`} />
    <p className={`${classes}__text`}>{title}</p>
  </div>
);
SimpleTitle.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.string,
};

export default SimpleTitle;
