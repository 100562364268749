import React from "react";
import PG_AVH_LEARNING_CONTENT from "./index.content";
import GetList from "../GetList";
import SVG_NOSUCHTOOLICON from "../../../assets/images/vector/pages/amzscout-vs-helium-10/ProductResearch/NoSuchTool.svg";
import SVG_AMZSCOUT from "../../../assets/images/vector/pages/amzscout-vs-helium-10/Learning/AMZScout_logo.svg";
import SVG_HELIUM10 from "../../../assets/images/vector/pages/amzscout-vs-helium-10/Learning/Helium10_logo.svg";
import Screenshot from "../Screenshot";
import "./index.scss";

const Learning = () => {
  const { listsPictures, listParagraphs } = PG_AVH_LEARNING_CONTENT;

  return (
    <section className="PgAVH-L">
      <div className="PgAVH-L__wrapRowOne">
        <div className="container">
          <div className="PgAVH-L__sectionTitle PgAVH-L-sectionTitle">
            <h2 className="PgAVH-L-sectionTitle__title">
              Learning and Insights
            </h2>
            <p className="PgAVH-L-sectionTitle__text">
              Educational resources are essential for new Amazon sellers. Let’s
              see how the platforms compare:
            </p>
          </div>
          <div className="PgAVH-L__centerSection">
            <div className="PgAVH-L__columOne PgAVH-L-columOne">
              <div className="PgAVH-L__imageWrap">
                <img
                  src={SVG_HELIUM10}
                  alt="x"
                  className="PgAVH-L-columOne__logo"
                />
                <Screenshot
                  listPictures={listsPictures.Helium10}
                  classes="PgAVH-L-columOne__screenshot"
                />
              </div>
              <div className="PgAVH-L-columOne__textWrap">
                <p className="PgAVH-L-columOne__title">Helium 10</p>
                <GetList
                  list={listParagraphs.Helium10}
                  classes="PgAVH-L-columOne__listItem"
                  classesImg="PgAVH-L__marker"
                />
              </div>
            </div>
            <div className="PgAVH-L__columTwo PgAVH-L-columTwo">
              <div className="PgAVH-L__imageWrap">
                <img
                  src={SVG_AMZSCOUT}
                  alt="x"
                  className="PgAVH-L-columTwo__logo"
                />
                <Screenshot
                  listPictures={listsPictures.AMZScout}
                  classes="PgAVH-L-columTwo__screenshot"
                />
              </div>
              <div className="PgAVH-L-columTwo__textWrap">
                <p className="PgAVH-L-columTwo__title">AMZScout</p>
                <GetList
                  list={listParagraphs.AMZScout}
                  classes="PgAVH-L-columTwo__listItem"
                  classesImg="PgAVH-L__marker"
                />
              </div>
            </div>
            <div className="PgAVH-L__noSuchTool PgAVH-L-noSuchTool">
              <img src={SVG_NOSUCHTOOLICON} alt="x" />
              <span>No such course</span>
            </div>
          </div>
        </div>
        <div className="PgAVH-L__line  PgAVH-L__line_one" />
        <div className="PgAVH-L__line  PgAVH-L__line_two" />
      </div>
    </section>
  );
};

export default Learning;
