import React from "react";
import BEST_PRICING_BOTTOM_SECTION from "./index.content";
import "./index.scss";

const BottomSection = () => {
  return (
    <div className="PgAVH-BP__BottomSection PgAVH-BP-BottomSection">
      {BEST_PRICING_BOTTOM_SECTION.map((text, i) => (
        <p key={i} className="PgAVH-BP-BottomSection__text">
          {text}
        </p>
      ))}
    </div>
  );
};

export default BottomSection;
