import React from "react";
import TECHNICAL_SUPPORT_INFO_BOARD from "./index.content";
import GetList from "../../../GetList";
import "./index.scss";

const Board = () => {
  return (
    <div className="PgAVH-TS-I-B">
      <h3 className="PgAVH-TS-I-B__title">
        Here are the types of concerns that AMZScout tech support can resolve:
      </h3>
      <GetList
        list={TECHNICAL_SUPPORT_INFO_BOARD}
        classes="PgAVH-TS-I-B__listItem"
        classesImg="PgAVH-TS-I-B__marker"
      />
    </div>
  );
};

export default Board;
