import React from "react"

const PG_AVH_FREE_TOOLS_CONTENT = {
  listParagraphs: [
    [
      { textParagraph: "Amazon Sales Estimator" },
      {
        textParagraph:
          "This tool gives you a rough estimation of how many sales you can expect each month given the ranking of the product",
      },
    ],
    [
      { textParagraph: "FBA Fees Calculator" },
      {
        textParagraph:
          "This Chrome extension gives you an immediate overview of FBA fees, sales volume, margins, and revenue for each product",
      },
    ],
    [
      { textParagraph: "Stock Stats" },
      {
        textParagraph:
          "This tool lets you see inventory levels for the various sellers on an Amazon listing",
      },
    ],
  ],
}

export default PG_AVH_FREE_TOOLS_CONTENT
