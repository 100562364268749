import React from "react";
import PG_AVH_FREE_TOOLS_CONTENT from "./index.content";
import GetList from "../GetList";
import "./index.scss";

const FreeTools = () => {
  const { listParagraphs } = PG_AVH_FREE_TOOLS_CONTENT;
  return (
    <section className="PgAVH-FreeTools">
      <div className="container">
        <h2 className="PgAVH-FreeTools__title">Free Tools</h2>
        <p className="PgAVH-FreeTools__subTitle">
          This section is exclusive to AMZScout. Helium 10 doesn’t offer any
          free tools outside of its core platform.
        </p>
        <GetList
          list={listParagraphs}
          classes="PgAVH-FreeTools__listItem"
          classesImg="PgAVH-FreeTools__marker"
        />
      </div>
    </section>
  );
};

export default FreeTools;
