import React from "react";
import TitleSection from "./TitleSection";
import CenterSection from "./CenterSection";
import BottomSection from "./BottomSection";
import "./index.scss";

const BestPricing = ({ pricingRef }) => {
  return (
    <section className="PgAVH-BP">
      <div className="PgAVH-BP__wrapRowOne">
        <div className="container">
          <TitleSection />
          <CenterSection pricingRef={pricingRef} />
        </div>
      </div>
      <div className="PgAVH-BP__wrapRowTwo">
        <div className="container">
          <BottomSection />
        </div>
      </div>
    </section>
  );
};

export default BestPricing;
