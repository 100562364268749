import PNG from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/ProductResearch/ProductResearch_Helium10@desk.png";
import PNG_2x from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/ProductResearch/ProductResearch_Helium10@desk2x.png";
import WEBP from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/ProductResearch/ProductResearch_Helium10@desk.webp";
import WEBP_2x from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/ProductResearch/ProductResearch_Helium10@desk2x.webp";

import PNG_Mob from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/ProductResearch/ProductResearch_Helium10@mob.png";
import PNG_Mob2x from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/ProductResearch/ProductResearch_Helium10@mob2x.png";
import WEBP_Mob from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/ProductResearch/ProductResearch_Helium10@mob.webp";
import WEBP_Mob2x from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/ProductResearch/ProductResearch_Helium10@mob2x.webp";

const PRODUCT_RESEARCH_H10 = {
  listsPictures: {
    deskImgPng: PNG,
    deskImgPng2x: PNG_2x,
    deskImgWebp: WEBP,
    deskImgWebp2x: WEBP_2x,

    mobImgPng: PNG_Mob,
    mobImgPng2x: PNG_Mob2x,
    mobImgWebp: WEBP_Mob,
    mobImgWebp2x: WEBP_Mob2x
  },
  listsText: [
    [
      { textParagraph: "Black Box" },
      {
        textParagraph:
          "Helium 10’s Black Box is a product database containing data for millions of items on Amazon. With the tool, you search for particular products by entering a keyword or using one of the several filters. The results will then show you the price, monthly sales, revenue, reviews, and Best Seller Rank (BSR) for the product."
      },
      {
        textParagraph:
          "The database also allows you to search for keywords, competitors, niches, and product targeting."
      }
    ],
    [
      { textParagraph: "X-Ray" },
      {
        textParagraph:
          "X-Ray is a Google Chrome Extension that offers similar functionality to the Black Box database. You can use it while browsing on Amazon to pull up the sales data for any product or niche. The metrics are mostly the same but also include the estimated FBA fees."
      },
      {
        textParagraph:
          "When searching a niche, you can see the average stats for the top products. There is also a success store (measured on a scale of 1 - 100) that shows the overall viability of the niche."
      }
    ],
    [
      { textParagraph: "Trendster" },
      {
        textParagraph:
          "Trendster is a tool that lets you visualize the trends for different keywords and products on Amazon. You can use these to see how sales and prices are changing over a given period of time."
      }
    ]
  ]
};
export default PRODUCT_RESEARCH_H10;
