import React from "react";
import SvgMarker from "../../../../assets/images/vector/components/common/tickInACircle.svg";
import PRODUCT_RESEARCH_BOTTOM_SECTION from "./index.content";
import GetList from "../../GetList";
import "./index.scss";

const BottomSection = () => {
  const { firstPartList, secondPartList } = PRODUCT_RESEARCH_BOTTOM_SECTION;
  return (
    <div className="PgAVH-PR-BS">
      <p className="PgAVH-PR-BS__title">Key Differences</p>
      <div className="PgAVH-PR-BS__listWrap">
        <GetList
          list={firstPartList}
          classes="PgAVH-PR-BS__listItem"
          img={SvgMarker}
        />
        <GetList
          list={secondPartList}
          classes="PgAVH-PR-BS__listItem"
          img={SvgMarker}
        />
      </div>
    </div>
  );
};

export default BottomSection;
