import React from "react";
import PropTypes from "prop-types";

const Screenshot = ({ classes, listPictures }) => (
  <picture>
    <source
      type="image/webp"
      media="(min-width: 992px)"
      srcSet={`${listPictures.deskImgWebp} 1x, ${listPictures.deskImgWebp2x} 2x`}
    />
    <source
      media="(min-width: 992px)"
      srcSet={`${listPictures.deskImgPng} 1x, ${listPictures.deskImgPng2x} 2x`}
    />
    <source
      type="image/webp"
      media="(max-width: 991px)"
      srcSet={`${listPictures.mobImgWebp} 1x, ${listPictures.mobImgWebp2x} 2x`}
    />
    <source
      media="(max-width: 991px)"
      srcSet={`${listPictures.mobImgPng} 1x, ${listPictures.mobImgPng2x} 2x`}
    />
    <img
      src={listPictures.deskImgPng}
      className={classes}
      alt="img"
      loading="lazy"
    />
  </picture>
);
Screenshot.propTypes = {
  listPictures: PropTypes.object,
  classes: PropTypes.string,
};

export default Screenshot;
