import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

const Ratio = ({ data }) => {
  const { excellent, bad } = data;
  return (
    <div className="PgAVH-TS-Comparison-Column-About-Ratio">
      <p className="PgAVH-TS-Comparison-Column-About-Ratio__excellent">
        {excellent}% excellent
      </p>
      <div className="PgAVH-TS-Comparison-Column-About-Ratio__line" />
      <p className="PgAVH-TS-Comparison-Column-About-Ratio__bad">{bad}% bad</p>
    </div>
  );
};
Ratio.propTypes = {
  data: PropTypes.object,
};

export default Ratio;
