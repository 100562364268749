import React from "react";

const BEST_PRICING_CENTER_SECTION = {
  Helium_10: [
    [{ textParagraph: "Starter: $39/month ($339/year)" }],
    [{ textParagraph: "Platinum: $99/month ($999/year)" }],
    [{ textParagraph: "Diamond:  $249/month ($2,499/year)" }],
    [{ textParagraph: "Elite: $399/month" }]
  ],
  AMZScout: [
    [{ textParagraph: "Amazon Seller’s Bundle: $49.99/month ($399.99/year)" }],
    [{ textParagraph: "Amazon Seller’s Bundle with Add-on*: $59.99/month ($499.99/year)" }],
    [{ textParagraph: "PRO Extension: $279.99/year ($599.99 lifetime membership)" }],
    [{ textParagraph: "Amazon Seller’s Bundle: $1,599.99 lifetime membership" }]
  ]
};
export default BEST_PRICING_CENTER_SECTION;
