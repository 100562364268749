import React from "react";
import SVG_SCREEN from "../../../assets/images/vector/pages/amzscout-vs-helium-10/LearnMore/LearnMore_logs.svg";
import ScrollLink from "../../common/ScrollLink";
import "./index.scss";

const LearnMore = ({ usersSayingRef }) => (
  <section className="PgAVH-LM">
    <div className="container">
      <div className="PgAVH-LM__content">
        <img className="PgAVH-LM__screen" src={SVG_SCREEN} alt="img" />
        <div className="PgAVH-LM__info">
          <p>Compare what different users think about these tools</p>
          <ScrollLink refParameter={usersSayingRef} classes="PgAVH-LM__link">
            Learn More
          </ScrollLink>
        </div>
      </div>
    </div>
  </section>
);

export default LearnMore;
