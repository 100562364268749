import React from "react";

import JPEG_AMZS_1 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/AMZS_reviews_1.jpg";
import JPEG2x_AMZS_1 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/AMZS_reviews_1@2x.jpg";
import JPEGMob_AMZS_1 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/AMZS_reviews_1@mob.jpg";
import JPEGMob2x_AMZS_1 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/AMZS_reviews_1@mob_2x.jpg";
import WEBP_AMZS_1 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/AMZS_reviews_1.webp";
import WEBP2x_AMZS_1 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/AMZS_reviews_1@2x.webp";
import WEBPMob_AMZS_1 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/AMZS_reviews_1@mob.webp";
import WEBPMob2x_AMZS_1 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/AMZS_reviews_1@mob_2x.webp";

import JPEG_AMZS_2_1 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/AMZS_reviews_2-1.jpg";
import JPEG2x_AMZS_2_1 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/AMZS_reviews_2-1@2x.jpg";
import JPEGMob_AMZS_2_1 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/AMZS_reviews_2-1@mob.jpg";
import JPEGMob2x_AMZS_2_1 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/AMZS_reviews_2-1@mob_2x.jpg";
import WEBP_AMZS_2_1 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/AMZS_reviews_2-1.webp";
import WEBP2x_AMZS_2_1 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/AMZS_reviews_2-1@2x.webp";
import WEBPMob_AMZS_2_1 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/AMZS_reviews_2-1@mob.webp";
import WEBPMob2x_AMZS_2_1 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/AMZS_reviews_2-1@mob_2x.webp";

import JPEG_AMZS_2_2 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/AMZS_reviews_2-2.jpg";
import JPEG2x_AMZS_2_2 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/AMZS_reviews_2-2@2x.jpg";
import JPEGMob_AMZS_2_2 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/AMZS_reviews_2-2@mob.jpg";
import JPEGMob2x_AMZS_2_2 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/AMZS_reviews_2-2@mob_2x.jpg";
import WEBP_AMZS_2_2 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/AMZS_reviews_2-2.webp";
import WEBP2x_AMZS_2_2 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/AMZS_reviews_2-2@2x.webp";
import WEBPMob_AMZS_2_2 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/AMZS_reviews_2-2@mob.webp";
import WEBPMob2x_AMZS_2_2 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/AMZS_reviews_2-2@mob_2x.webp";

import JPEG_AMZS_3 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/AMZS_reviews_3.jpg";
import JPEG2x_AMZS_3 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/AMZS_reviews_3@2x.jpg";
import JPEGMob_AMZS_3 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/AMZS_reviews_3@mob.jpg";
import JPEGMob2x_AMZS_3 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/AMZS_reviews_3@mob_2x.jpg";
import WEBP_AMZS_3 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/AMZS_reviews_3.webp";
import WEBP2x_AMZS_3 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/AMZS_reviews_3@2x.webp";
import WEBPMob_AMZS_3 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/AMZS_reviews_3@mob.webp";
import WEBPMob2x_AMZS_3 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/AMZS_reviews_3@mob_2x.webp";


import JPEG_H10_1 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/H10_reviews_1.jpg";
import JPEG2x_H10_1 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/H10_reviews_1@2x.jpg";
import JPEGMob_H10_1 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/H10_reviews_1@mob.jpg";
import JPEGMob2x_H10_1 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/H10_reviews_1@mob_2x.jpg";
import WEBP_H10_1 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/H10_reviews_1.webp";
import WEBP2x_H10_1 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/H10_reviews_1.webp";
import WEBPMob_H10_1 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/H10_reviews_1.webp";
import WEBPMob2x_H10_1 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/H10_reviews_1@mob_2x.webp";

import JPEG_H10_2_1 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/H10_reviews_2-1.jpg";
import JPEG2x_H10_2_1 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/H10_reviews_2-1@2x.jpg";
import JPEGMob_H10_2_1 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/H10_reviews_2-1@mob.jpg";
import JPEGMob2x_H10_2_1 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/H10_reviews_2-1@mob_2x.jpg";
import WEBP_H10_2_1 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/H10_reviews_2-1.webp";
import WEBP2x_H10_2_1 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/H10_reviews_2-1.webp";
import WEBPMob_H10_2_1 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/H10_reviews_2-1.webp";
import WEBPMob2x_H10_2_1 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/H10_reviews_2-1@mob_2x.webp";

import JPEG_H10_2_2 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/H10_reviews_2-2.jpg";
import JPEG2x_H10_2_2 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/H10_reviews_2-2@2x.jpg";
import JPEGMob_H10_2_2 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/H10_reviews_2-2@mob.jpg";
import JPEGMob2x_H10_2_2 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/H10_reviews_2-2@mob_2x.jpg";
import WEBP_H10_2_2 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/H10_reviews_2-2.webp";
import WEBP2x_H10_2_2 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/H10_reviews_2-2.webp";
import WEBPMob_H10_2_2 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/H10_reviews_2-2.webp";
import WEBPMob2x_H10_2_2 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/H10_reviews_2-2@mob_2x.webp";

import JPEG_H10_3 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/H10_reviews_3.jpg";
import JPEG2x_H10_3 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/H10_reviews_3@2x.jpg";
import JPEGMob_H10_3 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/H10_reviews_3@mob.jpg";
import JPEGMob2x_H10_3 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/H10_reviews_3@mob_2x.jpg";
import WEBP_H10_3 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/H10_reviews_3.webp";
import WEBP2x_H10_3 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/H10_reviews_3.webp";
import WEBPMob_H10_3 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/H10_reviews_3.webp";
import WEBPMob2x_H10_3 from "../../../../../assets/images/raster/pages/amzscout-vs-helium-10/UsersSaying/H10_reviews_3@mob_2x.webp";

import SVG_LOGO_H10 from "../../../../../assets/images/vector/pages/amzscout-vs-helium-10/TechnicalSupport/TS_Helium_10_logo.svg";
import SVG_LOGO_AMZS from "../../../../../assets/images/vector/pages/amzscout-vs-helium-10/TechnicalSupport/TS_AMZScout_logo.svg";

export const AMZS_CONTENT = {
  about: {
    logo: SVG_LOGO_AMZS,
    title: "AMZScout"
  },
  listReviews: [
    {
      title: "Pricing Plans",
      listParagraphs: [
        [
          { textParagraph: "Clear and transparent plans with many open features" },
          { textParagraph: "With the most popular Amazon Seller's Bundle Plan, for $49.99/month, AMZScout offers full access to all tools and features (except Amazon Dropshipping, Arbitrage, and Wholesale Extension, which you have the option to buy with the Bundle for a good price)." },
          { textParagraph: "This means that when you buy this plan, you get all of the tools you need for your research, from performing keyword searches to finding profitable products to sell." },
        ],
      ],
      listScreenshots: [
        {
          JPEG: JPEG_AMZS_1,
          JPEG2x: JPEG2x_AMZS_1,
          JPEGMob: JPEGMob_AMZS_1,
          JPEGMob2x: JPEGMob2x_AMZS_1,
          WEBP: WEBP_AMZS_1,
          WEBP2x: WEBP2x_AMZS_1,
          WEBPMob: WEBPMob_AMZS_1,
          WEBPMob2x: WEBPMob2x_AMZS_1,
        },
      ],
    },
    {
      title: "Refunding Process",
      listParagraphs: [
        [
          { textParagraph: "No hidden fees or commissions upon subscription cancelation" },
          { textParagraph: "If you decide to cancel your AMZScout subscription, you will absolutely get your money back. The company guarantees a quick refund during the first 10 days of using their software." },
        ],
      ],
      listScreenshots: [
        {
          JPEG: JPEG_AMZS_2_1,
          JPEG2x: JPEG2x_AMZS_2_1,
          JPEGMob: JPEGMob_AMZS_2_1,
          JPEGMob2x: JPEGMob2x_AMZS_2_1,
          WEBP: WEBP_AMZS_2_1,
          WEBP2x: WEBP2x_AMZS_2_1,
          WEBPMob: WEBPMob_AMZS_2_1,
          WEBPMob2x: WEBPMob2x_AMZS_2_1,
        },
        {
          JPEG: JPEG_AMZS_2_2,
          JPEG2x: JPEG2x_AMZS_2_2,
          JPEGMob: JPEGMob_AMZS_2_2,
          JPEGMob2x: JPEGMob2x_AMZS_2_2,
          WEBP: WEBP_AMZS_2_2,
          WEBP2x: WEBP2x_AMZS_2_2,
          WEBPMob: WEBPMob_AMZS_2_2,
          WEBPMob2x: WEBPMob2x_AMZS_2_2,
        },
      ],
    },
    {
      title: "Price Changes",
      listParagraphs: [
        [
          { textParagraph: "Fixed price for AMZScout clients" },
          { textParagraph: "Once you start your AMZScout subscription, the price will stay the same forever. Your payment amount will always remain consistent, even if the general pricing terms change. Clear and transparent payment terms are good for your budget planning, and they reduce stress." },
        ],
      ],
      listScreenshots: [
        {
          JPEG: JPEG_AMZS_3,
          JPEG2x: JPEG2x_AMZS_3,
          JPEGMob: JPEGMob_AMZS_3,
          JPEGMob2x: JPEGMob2x_AMZS_3,
          WEBP: WEBP_AMZS_3,
          WEBP2x: WEBP2x_AMZS_3,
          WEBPMob: WEBPMob_AMZS_3,
          WEBPMob2x: WEBPMob2x_AMZS_3,
        },
      ],
    }
  ]
};

export const H10_CONTENT = {
  about: {
    logo: SVG_LOGO_H10,
    title: "Helium 10"
  },
  listReviews: [
    {
      title: "Pricing Plans",
      listParagraphs: [
        [
          { textParagraph: "Starter plan is limited and unusable" },
          { textParagraph: "Helium 10’s Starter Plan offers «Limited», and sometimes even impossible to use, functions, for $39/month. Their next plan with more features is already $99/month." },
          { textParagraph: "«Limited» means “available to try for a limited amount of time”. However, it appears to not always work correctly." }
        ]
      ],
      listScreenshots: [
        {
          JPEG: JPEG_H10_1,
          JPEG2x: JPEG2x_H10_1,
          JPEGMob: JPEGMob_H10_1,
          JPEGMob2x: JPEGMob2x_H10_1,
          WEBP: WEBP_H10_1,
          WEBP2x: WEBP2x_H10_1,
          WEBPMob: WEBPMob_H10_1,
          WEBPMob2x: WEBPMob2x_H10_1,
        },
      ],
    },
    {
      title: "Refunding Process",
      listParagraphs: [
        [
          { textParagraph: "Refunding process can be quite challenging" },
          { textParagraph: "Helium 10 offers a 7-day money-back guarantee period, however, it looks like their refunding process can be daunting here." },
        ]
      ],
      listScreenshots: [
        {
          JPEG: JPEG_H10_2_1,
          JPEG2x: JPEG2x_H10_2_1,
          JPEGMob: JPEGMob_H10_2_1,
          JPEGMob2x: JPEGMob2x_H10_2_1,
          WEBP: WEBP_H10_2_1,
          WEBP2x: WEBP2x_H10_2_1,
          WEBPMob: WEBPMob_H10_2_1,
          WEBPMob2x: WEBPMob2x_H10_2_1,
        },
        {
          JPEG: JPEG_H10_2_2,
          JPEG2x: JPEG2x_H10_2_2,
          JPEGMob: JPEGMob_H10_2_2,
          JPEGMob2x: JPEGMob2x_H10_2_2,
          WEBP: WEBP_H10_2_2,
          WEBP2x: WEBP2x_H10_2_2,
          WEBPMob: WEBPMob_H10_2_2,
          WEBPMob2x: WEBPMob2x_H10_2_2,
        },
      ],
    },
    {
      title: "Price Changes",
      listParagraphs: [
        [
          { textParagraph: "Price hikes even when the price was fixed" },
          { textParagraph: "Going through Helium 10 reviews, we found information about price hikes, even when the price was supposedly fixed in the agreement or stated at the payment stage:" },
        ],
      ],
      listScreenshots: [
        {
          JPEG: JPEG_H10_3,
          JPEG2x: JPEG2x_H10_3,
          JPEGMob: JPEGMob_H10_3,
          JPEGMob2x: JPEGMob2x_H10_3,
          WEBP: WEBP_H10_3,
          WEBP2x: WEBP2x_H10_3,
          WEBPMob: WEBPMob_H10_3,
          WEBPMob2x: WEBPMob2x_H10_3,
        },
      ],
    }
  ]
};



