import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../../../../helpers";
import GetList from "../../../../GetList";
import SimpleScreenshot from "../../../../../common/UI/SimpleScreenshot";
import "./index.scss";

const Reviews = ({ data }) => {
  const { title, listParagraphs, listScreenshots } = data;
  return (
    <div className="PgAVH-US-Comparison-Column-Reviews">
      <div className="PgAVH-US-Comparison-Column-Reviews__title">
        <p>{title}</p>
      </div>
      <GetList
        list={listParagraphs}
        classes="PgAVH-US-Comparison-Column-Reviews__listItem"
        classesImg="PgAVH-US-Comparison-Column-Reviews__marker"
      />
      <div className="PgAVH-US-Comparison-Column-Reviews__listScreenshots">
        {listScreenshots.map((screenshot, i) => (
          <SimpleScreenshot
            key={i}
            from={768}
            to={767}
            classes={getTrueClasses(
              "PgAVH-US-Comparison-Column-Reviews__Screenshot",
              i === 1 && "PgAVH-US-Comparison-Column-Reviews__Screenshot_two"
            )}
            screenshot={screenshot}
          />
        ))}
      </div>
    </div>
  );
};
Reviews.propTypes = {
  data: PropTypes.object,
};

export default Reviews;
