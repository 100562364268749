import React from "react";
import "./index.scss";

const Title = () => (
  <div className="PgAVH-US__Title PgAVH-US-Title">
    <div className="container">
      <h2 className="PgAVH-US-Title__title">What Users are Saying*</h2>
      <p className="PgAVH-US-Title__subTitle">* Based on the Reviews</p>
      <p className="PgAVH-US-Title__text">
        Let’s compare what real users think about their experiences with these
        tools.
      </p>
    </div>
  </div>
);

export default Title;
