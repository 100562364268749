const PRODUCT_RESEARCH_BOTTOM_SECTION = {
  firstPartList: [
    [
      { textParagraph: "Depth of Database" },
      {
        textParagraph:
          "AMZScout has more products in its database. Specifically, the tool includes over 600 million Amazon products compared to the 450+ million found in Black Box. With a more extensive database, you can surface more products for your business."
      }
    ],
    [
      { textParagraph: "Listing Quality Score" },
      {
        textParagraph:
          "AMZScout includes a Listing Quality Score (LQS) feature in both the PRO Extension and the Product Database. With the LQS, you can see what niches will be more difficult to enter."
      }
    ]
  ],
  secondPartList: [
    [
      { textParagraph: "Weekly Trends Reports" },
      {
        textParagraph:
          "With AMZScout, you can find weekly ideas of promising niches. These are selected by a special algorithm to locate the best upcoming opportunities. This feature is available to users with an annual subscription."
      }
    ],
    [
      {
        textParagraph:
          "Product Research for Dropshippers, Wholesalers and Arbitrage Sellers"
      },
      {
        textParagraph:
          "Helium 10’s research tools focus primarily on private label sellers and businesses that manufacturer their own products. On the other hand, AMZScout offers product research capabilities for all sellers, including resellers. If you are sourcing your products wholesale or through dropshipping, you can take advantage of this to get better insights for your business."
      }
    ]
  ]
};
export default PRODUCT_RESEARCH_BOTTOM_SECTION;
