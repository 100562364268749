import React from "react";
import PropTypes from "prop-types";
import ParagraphWithIcon from "./ParagraphWithIcon";
import ParagraphWithList from "./ParagraphWithList";

const Item = ({ list, classes }) => {
  return (
    <div className={classes}>
      {list.map((obj, i) => {
        const key = `${i}${classes}`;
        if (obj.icon) {
          return <ParagraphWithIcon key={key} obj={obj} classes={classes} />;
        }
        if (obj.list) {
          return (
            <ParagraphWithList key={key} list={obj.list} classes={classes} />
          );
        }
        return <p key={key}>{obj.textParagraph}</p>;
      })}
    </div>
  );
};
Item.propTypes = {
  list: PropTypes.array,
  classes: PropTypes.string,
};
export default Item;
