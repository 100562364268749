import React from "react";
import PropTypes from "prop-types";
import About from "./About";
import Reviews from "./Reviews";
import "./index.scss";

const Column = ({ data }) => {
  const { about, listReviews } = data;
  return (
    <div className="PgAVH-US-Comparison-Column">
      <About data={about} />
      <div className="PgAVH-US-Comparison-Column__listReviews">
        {listReviews.map((reviews, i) => (
          <Reviews key={i} data={reviews} />
        ))}
      </div>
    </div>
  );
};
Column.propTypes = {
  data: PropTypes.object,
};

export default Column;
