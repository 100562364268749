import React from "react";
import PropTypes from "prop-types";

const Marker = ({ img, classesImg }) =>
  img ? <img src={img} alt="." /> : <div className={classesImg ?? ""} />;
Marker.propTypes = {
  img: PropTypes.string,
  classesImg: PropTypes.string,
};
export default Marker;
