import React from "react";
import { AMZS_CONTENT, H10_CONTENT } from "./Column/index.content";
import Column from "./Column";
import "./index.scss";

const Comparison = () => {
  return (
    <div className="PgAVH-US-Comparison">
      <div className="container">
        <div className="PgAVH-US-Comparison__wrap">
          <Column data={H10_CONTENT} />
          <Column data={AMZS_CONTENT} />
        </div>
        <div />
      </div>
      <div className="PgAVH-US-Comparison__line PgAVH-US-Comparison__line_one" />
      <div className="PgAVH-US-Comparison__line PgAVH-US-Comparison__line_two" />
      <div className="PgAVH-US-Comparison__line PgAVH-US-Comparison__line_three" />
    </div>
  );
};

export default Comparison;
