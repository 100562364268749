import React from "react";
import PropTypes from "prop-types";
import SVG_LOGO from "../../../../../../../assets/images/vector/pages/amzscout-vs-helium-10/TechnicalSupport/Trustpilot_logo.svg";
import "./index.scss";

const Stars = ({ data }) => {
  const { sum, img } = data;
  return (
    <div className="PgAVH-TS-C-C-A-Stars">
      <img
        src={SVG_LOGO}
        alt="Trustpilot"
        className="PgAVH-TS-C-C-A-Stars__logo"
      />
      <img src={img} alt="stars" className="PgAVH-TS-C-C-A-Stars__scale" />
      <p className="PgAVH-TS-C-C-A-Stars__sum">{sum}</p>
    </div>
  );
};
Stars.propTypes = {
  data: PropTypes.object,
};

export default Stars;
