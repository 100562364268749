import React from "react";
import "./index.scss";

const SectionTitle = () => {
  return (
    <div className="PgAVH-PR-ST">
      <h2 className="PgAVH-PR-ST__title">Product Research</h2>
      <p className="PgAVH-PR-ST__text">
        Let’s start our review by comparing the two platforms in terms of
        product research
      </p>
    </div>
  );
};

export default SectionTitle;
